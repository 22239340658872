<template>
	<div v-if="componentForView">
		<component :is="componentForView" :pages="pages" />
	</div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

const CompanyProfile = defineAsyncComponent(() =>
	import('@/modules/broker/pages/Profile/CompanyProfile.vue')
)
const AgentProfile = defineAsyncComponent(() =>
	import('@/modules/broker/pages/Profile/BrokerProfile.vue')
)

export default {
	components: {
		CompanyProfile,
		AgentProfile
	},
	data() {
		return {
			componentForView: null
		}
	},
	computed: {
		pages() {
      if (this.$store.state.broker.role === 'broker') {
        return {
          first: {
            title: this.$t('general.home'),
            srcName: 'BrokerSearch'
          },
          current:
            this.$t('web.agent_profile_title')
        }
      } else {
        return {
          current:
            this.$t('general.home')
        }
      }
		}
	},
	beforeMount() {
		const {
			state: { broker, uiLanguage },
			commit
		} = this.$store
		if (broker.role === 'broker') {
			this.componentForView = 'AgentProfile'
		} else if (broker.role === 'agency') {
			this.componentForView = 'CompanyProfile'
		} else {
			commit('updateToken', null)

			this.$router.push({
				name: 'Login',
				params: { lang: uiLanguage }
			})
		}
	}
}
</script>
